// Libs
import uuidv4 from "uuid/v4";
import jsonToFormData from "json-form-data";
import ls from "local-storage";

// Context
import * as UserContext from "./UserContext";

// Config
import config from "../config";

const APIStages = {
  dev: {
    root: config.devApiUrl
  },
  prod: {
    root: config.prodApiUrl
  }
}

export const APIConstants = {
  invitedUser: {
    nexus: "/invitedUser/nexus",
    create: "/invitedUser/create",
    getByEmail: "/invitedUser/getByEmail",
    getAll: "/invitedUser/getAll"
  },
  user: {
    nexus: "/user/nexus",
    authenticateGoogleSignIn: "/user/authenticateGoogleSignIn",
    getById: "/user/getById",
    getAll: "/user/getAll",
  },
  reminder: {
    nexus: "/reminder/nexus",
    get: "/reminder/get",
    create: "/reminder/create",
    update: "/reminder/update",
    deliver: "/reminder/deliver",
    delete: "/reminder/delete"
  },
  linkedService: {
    nexus: "/linkedService/nexus",
    create: "/linkedService/create"
  }
};


const PRODUCTON_DOMAIN = 'zzreminders.net';

var requestMetrics = {};

function countRoute(route) {
  var metricsResult = requestMetrics[route];
  if (metricsResult != null) {
    metricsResult += 1;
    requestMetrics[route] = metricsResult
  } else {
    requestMetrics[route] = 1;
  }

  // console.log(requestMetrics);
}

function environmentSingleton() {
  if (window.location.href.indexOf('localhost') !== -1) {
    return "local";
  } else if (window.location.href.indexOf(PRODUCTON_DOMAIN) !== -1) {
    return "prod"
  }
  return "";
}

function getRoot(environment) {
  if (environment === "local") {
    return APIStages.dev.root;
  } else if (environment === "prod") {
    return APIStages.prod.root;
  }
}

export default function API() { return }

// S3 conveniences

export function encodeS3URL(baseURL) {
  return encodeURI(baseURL).replace("+","%2B");
}

export function createBasicRequestData() {
  let authToken = ls.get('authToken');

  return {
    userId: UserContext.getCurrentUserId(),
    organizationId: UserContext.getCurrentOrganizationId(),
    authToken: authToken
  }
}

export function requestTo(nexus, route, requestData) {
  return new Promise( async (resolve, reject) => {
    requestData.route = route;

    countRoute(route);

    console.log(route, requestData);

    let headers = environmentSingleton() === "local" ? {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    } : {
      'Accept': 'application/json'
    }

    const url = getRoot(environmentSingleton()) + nexus
    const result = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestData) // allows for files to be passed with normal json
    });

    if (!result.ok) {
      const errorJson = await result.json();
      console.log(errorJson);
      reject(errorJson);
    } else {
      resolve(result.json());
    }
  });
}
