import moment from "moment";

export const dateFormat = "ddd MMM DD, YYYY";
export const dateFormatPicker = "ddd MMM DD";

export function parseUnix(timestamp) {
  return moment(timestamp, "x");
}

export function time(timestamp) {
  return parseUnix(timestamp).format("h:mm A");
}

export function date(timestamp) {
  return parseUnix(timestamp).format(dateFormat);
}

export function formatDate(momentDate) {
  return momentDate.format(dateFormat);
}

export function calendar(timestamp) {
  return moment(parseUnix(timestamp)).calendar(null, {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: 'dddd',
      lastDay: '[Yesterday]',
      lastWeek: 'MMM DD',
      sameElse: 'MMM DD'
  });
}

export function reminderIsPast(r) {
  if (!!r.trigger) {
    return r.trigger < moment().format('x');
  } else {
    console.error("[Date][remindederIsPast] Reminder missing trigger timestamp: ", r);
    return true;
  }
}