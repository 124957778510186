// FIXME

// React
import React, { useState } from "react";

// Components
import { useFormFields } from "../libs/hooksLib";

// Models
import * as InvitedUser from "../models/InvitedUser";
import * as User from "../models/User";

// Auth
import { GoogleLogin } from "react-google-login";
import config from "../config";

// Local Storage
import ls from "local-storage";

export default function LoginPage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });

  const [organizationId, setOrganizationid] = useState(null);

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    // TODO: Non-gmail login
  }

  const successResponseGoogle = async response => {
    // TODO: consolidate w/ signup.js repeated code (auth lib?)
    // since this also signs a new user up if they don't exist in db yet, a check
    // is run server-side to see if they were invited (if user doesn't exist)
    let result;
    try {
      result = await User.authenticateGoogleSignIn(response);
      console.log(result);
      ls.set("authData", result.user);
      ls.set("organizationId", result.organizationId);

      props.userHasAuthData(result);
    } catch (err) {
      alert(`Error: ${err.message}`)
    }
  };

  const failureResponseGoogle = response => {
    //
  };

  return (
    <div className="login-container">
      <div className="title"> 
        Login to ZZ Reminders
      </div>
      <form onSubmit={handleSubmit}>
        {/*<Form.Group controlId="email" bssize="large">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="password" bssize="large">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <LoaderButton
          block
          type="submit"
          bssize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Login
        </LoaderButton>*/}
        <GoogleLogin
          clientId="918520629152-s0fe2d05en7k3b3kg953p08tsq3ahlfq.apps.googleusercontent.com"
          buttonText="Login with Google"
          offline={false}
          onSuccess={successResponseGoogle}
          onFailure={failureResponseGoogle}
          cookiePolicy={"single_host_origin"}
        />
      </form>
    </div>
  );
}
