import React, { useCallback, useMemo, useState } from "react";

import GlobalContext from "../contexts/GlobalContext";

import Header from "./Header";

export default function Root(props) {

  const [focusedItem, _setFocusedItem] = useState(null);
  
  const setFocusedItem = useCallback(_setFocusedItem, []);
  const getApi = useMemo(() => {
    return { setFocusedItem }
  }, []);

  return (
    <GlobalContext.Provider value={{ focusedItem, ...getApi}}>
      <Header />
      <div className="hr" />
      {props.children}
    </ GlobalContext.Provider>
  )
}