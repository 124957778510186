import {APIConstants, requestTo, createBasicRequestData} from "../libs/API";

export default function Reminder() { return }

export async function get(email) {
  var requestData = createBasicRequestData();

  requestData.email = email;

  return requestTo(APIConstants.reminder.nexus, APIConstants.reminder.get, requestData);
}

export async function create(reminder) {
  var requestData = createBasicRequestData();

  console.log(requestData);

  requestData.reminder = reminder;

  return requestTo(APIConstants.reminder.nexus, APIConstants.reminder.create, requestData);
}

export async function update() {
  var requestData = createBasicRequestData();

  return requestTo(APIConstants.reminder.nexus, APIConstants.reminder.update, requestData);
}

export async function deleteReminderByThreadId(threadId) {
  var requestData = createBasicRequestData();

  requestData.threadId = threadId;

  return requestTo(APIConstants.reminder.nexus, APIConstants.reminder.delete, requestData);
}

export async function deliver() {
  var requestData = createBasicRequestData();

  return requestTo(APIConstants.reminder.nexus, APIConstants.reminder.deliver, requestData);
}
