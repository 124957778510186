import {APIConstants, requestTo, createBasicRequestData} from "../libs/API";

import ls from "local-storage";

export default function User() { return }

export function getFullName(user) {
  return user?.firstName ? `${user.firstName} ${user.lastName}` : "";
}

export function getInitials(user) {
  return getFullName(user)?.split(" ").map((name) => name[0]).join("");
}

export function getAvatarUrl(user) {
  return user?.avatarImage?.sizes?.original?.url || "";
}

export async function getAll() {
  var requestData = createBasicRequestData();

  return requestTo(APIConstants.user.nexus, APIConstants.user.getAll, requestData);
}

export async function getById(id) {
  var requestData = createBasicRequestData();

  requestData.id = id;

  return requestTo(APIConstants.user.nexus, APIConstants.user.getById, requestData);
}

export async function authenticateGoogleSignIn(googleSignInInfo) {
  const profileObj = googleSignInInfo.profileObj;

  const fullName = profileObj.name;
  const firstName = profileObj.givenName;
  const lastName = profileObj.familyName;
  const googleId = profileObj.googleId;
  const picture = profileObj.imageUrl;
  const email = profileObj.email;
  const accessToken = googleSignInInfo.accessToken;
  const tokenId = googleSignInInfo.tokenId;

  ls.set('authToken', tokenId);

  const requestData = {
    fullName: fullName,
    firstName: firstName,
    lastName: lastName,
    googleId: googleId,
    picture: picture,
    email: email,
    accessToken: accessToken,
    tokenId: tokenId,
    providerType: "google"
  };

  return requestTo(APIConstants.user.nexus, APIConstants.user.authenticateGoogleSignIn, requestData);
}
