// React
import React, {useState} from "react";

import {Button} from "antd";

// Components
import SignupStepServices from "./SignupStepServices"

export default function Signup(props) {
  const step = props.step || "services";
  let Step;
  
  switch (step) {
    case "services":
      Step = SignupStepServices;
      break;
  }

  console.log("step!", Step);

  return (
    <div className="signup-component">
      <div className="container">
        {Step && <Step />}
      </div>
    </div>
  );
}
