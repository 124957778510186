import React from "react";

import { Row, Col } from "antd";
import { MailOutlined, MessageOutlined } from "@ant-design/icons";

import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';

export default function DeliveryMethodStatus({ reminder, spacing=12, ...props }) {
  const isRich = reminder.isRich;
  const method = reminder.deliveryMethod;

  const recipients = Array.isArray(reminder.to) ? reminder.to : [reminder.to];
  const hasSmsRecipients = method === "sms" || recipients.some(r => isMobilePhone(r));
  const hasEmailRecipients = method === "email" || recipients.some(r => isEmail(r));

  return (
    <Row gutter={spacing}>
      {hasSmsRecipients &&
        <Col>
          <MessageOutlined />
        </Col>
      }
      {hasEmailRecipients &&
        <Col>
          <MailOutlined />
        </Col>
      }
    </Row>
  )
}