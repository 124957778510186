// React
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ls from "local-storage";
import moment from "moment";

import { dateFormat, dateFormatPicker } from '../libs/Date';
import * as Notification from "../libs/Notification";

// Components
import ReminderModal from "../components/ReminderModal";
import ReminderTable from "../components/ReminderTable";
import Sidebar from "../components/Sidebar";
import * as Modals from "../components/Modals";

import { Button, DatePicker, Input, Tabs } from "antd";
import { SearchOutlined } from '@ant-design/icons';


// Context
import GlobalContext from "../contexts/GlobalContext";

// Models
import * as Reminder from "../models/Reminder";


// Style
import 'react-virtualized/styles.css'; // only needs to be imported once

// AntD Sub Components
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

export default function Home(props) {
  const zzContext = React.useContext(GlobalContext);

  // Var used to seed data for the modal. Intentionally non-stateful.
  var modalItem;

  const [query, setQuery] = useState("");
  const [reminders, setReminders] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [dateRange, setDateRange] = useState(null);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    fetchReminders();
  }

  async function fetchReminders() {
    let email = ls.get("authData").email;
    let fetchedReminders = await Reminder.get(email);
    setReminders(fetchedReminders);
  }


  /// Event Handling

  // Reminder created callback
  function onReminderModalCreate(reminder) {
    dismissReminderModal();

    console.log("[Create] ", reminder)
    Notification.showSuccess(reminder)

    fetchReminders();
  }

  function onReminderModalUpdate(reminder) {
    dismissReminderModal();

    console.log("[Update] ", reminder)
    Notification.showUpdate(reminder)
  }

  function onReminderModalCancel() {
    zzContext.focusedItem = undefined;
    dismissReminderModal();
  }

  function dismissReminderModal() {
    setModalVisible(false);
    modalItem = undefined;
  }

  function onSidebarEdit() {
    // Update modal item with focused item
    modalItem = zzContext.focusedItem;
    console.log(modalItem);
    setModalVisible(true);
  }

  async function onSidebarDelete() {
    Modals.showDelete({
      reminder: zzContext.focusedItem,
      onOk: (async () => {
        let focusedReminder = zzContext.focusedItem;
        let res = await Reminder.deleteReminderByThreadId(focusedReminder.threadId);
        dismissReminderModal();

        fetchReminders();
      })
    })
  }

  function onCreateClicked(event) {
    // No item -> Create
    zzContext.focusedItem = undefined;
    modalItem = undefined;
    setModalVisible(true);
  }

  /// Content filtering

  // Returns the set of reminders filterered by the given arguments"
  // query: (string) - a string to match with reminder Titles, Contexts or Recipients
  // 
  // 3 possible params for the date dictionary are checked in this order of priority.
  // `dateRange` may be included with `upcoming` and `past` booleans as Upcoming and Completed
  // events are filtered within their own tabs:  
  // { dateRange }: ([Moment, Moment]) - a range of Moment dates within with to clamp results
  // { upcoming  }: (boolean) - indicates should only return future reminders
  // { past      }: (boolean) - indicates should only return past reminders
  function filteredReminders(query, { upcoming, past, dateRange }) {
    const q = query.toLowerCase();
    const now = moment().format('x');

    let [startDate, endDate] = [null, null];
    if (dateRange?.length > 1 && !!(dateRange[0] && dateRange[1])) {
      [startDate, endDate] = dateRange;
    }

    return reminders.filter((r) => {
      // Query
      let passesTextQuery = !!!q; // passes (true) if null query
      if (q) {
        passesTextQuery = false; // fails (false) if query exists and not staisfied
        if (r.isRich !== null && typeof r.isRich !== "undefined" && r.isRich) {
          // Rich Reminder
          const titleIncludes = !!r.title && r.title.toLowerCase().includes(q);
          const contextIncludes = !!r.context && r.context.toLowerCase().includes(q);
          const attrsIncludes = !!r.attrs && JSON.stringify(r.attrs).toLowerCase().includes(q);

          let toIncludes = false;
          if (!!r.to) {
            if (Array.isArray(r.to)) {
              toIncludes = r.to.some(recipient => recipient.toLowerCase().includes(q));
            } else {
              toIncludes = r.to.toLowerCase().includes(q);
            }
          }
          passesTextQuery = titleIncludes || contextIncludes || attrsIncludes || toIncludes;
        } else {
          // ZZ Bot Email Reminder
          const textIncludes = !!r.text && r.text.toLowerCase().includes(q);
          const subjectIncludes = !!r.subject && r.subject.toLowerCase().includes(q);
          const fromIncludes = !!r.from && r.from.toLowerCase().includes(q);
          passesTextQuery = textIncludes || subjectIncludes || fromIncludes;
        }
      }

      // Time Filtering
      let isWithinDateRange = true; // passes if no date info specified
      if (r.trigger) {
        if (startDate && endDate) {
          isWithinDateRange = moment(r.trigger).isBetween(startDate, endDate);
        }

        if (upcoming) {
          isWithinDateRange &= r.trigger > now;
        } else if (past) {
          isWithinDateRange &= r.trigger < now;
        }
      }

      return passesTextQuery && isWithinDateRange;
    })
  }

  function setDateRangeToday() {
    const today = moment().startOf('day');
    const tomorrow = moment().endOf('day');
    setDateRange([today, tomorrow])
  }

  const upcomingReminders = filteredReminders(query, { upcoming: true, dateRange: dateRange }).reverse();
  const pastReminders = filteredReminders(query, { past: true, dateRange: dateRange });
  const formatTabTitle = (title, query, dateRange, reminders) => `${title} ${(query?.length > 0 || dateRange) ? "(" + reminders.length + ")" : ""}`;

  return (
    <div className="home-container">
      <div className="content-container">
        <div className="header">
          <div className="table-header">
            <Input
              className="search"
              prefix={<SearchOutlined />}
              placeholder="Search"
              onChange={(e) => setQuery(e.target.value)}
              onSearch={setQuery}
              allowClear
            />
            <RangePicker
              className="range"
              value={dateRange}
              showToday={true}
              onChange={setDateRange}
              format={dateFormatPicker}
              allowClear
            />
            <Button
              className="today"
              onClick={(e) => { setDateRangeToday() }}
              style={{ marginLeft: "16px" }}>
              Today
              </Button>
          </div>
          <div className="rhs-header">
            <Button 
              type="primary" 
              onClick={onCreateClicked}>
              New Reminder
            </Button>
          </div>
        </div>
        <div className="content">
          <div className="reminders">
            <Tabs tabBarStyle={{ paddingLeft: "24px", backgroundColor: "#fafafa" }} defaultActiveKey="0">
              <TabPane tab={formatTabTitle("Upcoming", query, dateRange, upcomingReminders)} key="0">
                <ReminderTable reminders={upcomingReminders} />
              </TabPane>
              <TabPane tab={formatTabTitle("Completed", query, dateRange, pastReminders)} key="1">
                <ReminderTable reminders={pastReminders} />
              </TabPane>
            </Tabs>
          </div>
          <div className={`sidebar ${!!zzContext.focusedItem ? "is-focused" : ""}`}>
            <Sidebar
              reminder={zzContext.focusedItem}
              handlePrimaryAction={onSidebarEdit}
              handleDeleteAction={onSidebarDelete}
            />
          </div>
        </div>
      </div>

      {isModalVisible &&
        <ReminderModal
          reminder={zzContext.focusedItem}
          isVisible={isModalVisible}
          handleCreate={onReminderModalCreate}
          handleUpdate={onReminderModalUpdate}
          handleCancel={onReminderModalCancel}
          {...props}
        />}
    </div>
  )
}
