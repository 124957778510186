// node
import * as serviceWorker from "./serviceWorker";

// react
import React from "react";
import ReactDOM from "react-dom";

// style
import "./styles/styles.scss";

// apps
import App from "./App";

// middleware
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// enable hot module replacement
if (module.hot) {
  module.hot.accept();
}
