// React
import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";

// Router
import Routes from "./Routes";

// ant design
import "antd/dist/antd.css";

// Local Storage
import ls from 'local-storage';
import { getPreviousRecipients, setPreviousRecipients } from "./libs/Utility";

console.log('Running version', process.env.REACT_APP_COMMIT_HASH);

function App(props) {
  const [authData, userHasAuthData] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [currentDB, setCurrentDB] = useState({});

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    //
    if (authData == null) {
      const localStorageAuthData = ls.get('authData');
      if (!localStorageAuthData?.id) {
        // dont do anything, they are unauth
      } else {
        userHasAuthData(localStorageAuthData);
      }
    } else {
      ls.set('authData', authData);
    }

    setIsAuthenticating(false);

    // Ensure previous recipient storage is an array.
    const previousRecipients = getPreviousRecipients();
    if (!previousRecipients || !Array.isArray(previousRecipients)) {
      setPreviousRecipients([]);
    }
  }

  const localStorageAuthData = ls.get("authData");

  let email = "";
  if (localStorageAuthData == null) {
    // dont do anything, they are unauth
  } else {
    const providerType = localStorageAuthData.providerType;
    if (providerType === "amazon") {
      email = localStorageAuthData.email;
    } else if (providerType === "google") {
      email = localStorageAuthData.email;
    }
  }

  let organizationId = ls.get("organizationId");

  let appProps = { authData, userHasAuthData, email, organizationId };

  return (
    !isAuthenticating && (
      <div className="app">
        <div className="page">
          <Routes appProps={appProps} />
        </div>
      </div>
    )
  );
}

export default withRouter(App);
