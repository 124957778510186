import React from "react";

import { date, time, } from '../libs/Date';

import { Modal, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

export default function Modals(props) { return }

export function showDelete({ reminder, onOk, ...props }) {
  confirm({
    title: 'Delete this reminder?',
    icon: <ExclamationCircleOutlined />,
    content: reminder.title + ", " + date(reminder.trigger) + " at " + time(reminder.trigger),
    onOk: onOk,
    onCancel() {},
  });
}