import isEmail from 'validator/lib/isEmail';
import { parsePhoneNumberFromString as parsePhone } from 'libphonenumber-js';
import ls from "local-storage";

const _ = require('lodash');

/// Sanitization and String Utility
///

// Accepts either a single recipient string or an array of recipeints.
export const formatRecipientData = (data) => {
  let recipients;
  if (Array.isArray(data)) {
    recipients = data.slice();
  } else {
    recipients = [data];
  }
  return sanitizeRecipientStrings(recipients, true).join(', ');
};

// Trims and sanitizes for machine consumption. Yum.
export const sanitizeString = (string) => {
  return string.trim();
};

// Trims and sanitizes an array of strings.
export const sanitizeStrings = (stringArray) => {
  return stringArray.map(s => sanitizeString(s));
};

// Parses and sanitizes a raw field input value into a trimmed email string or a 
// formatted US national number. Undefined if non-email or non-US phone number.
// 
// format=true  |  (415) 555-1234
// format=false |  4155551234
export const sanitizeRecipientString = (recipientString, format) => {
  if (!recipientString || recipientString.length === 0) {
    return undefined;
  }

  const recipient = sanitizeString(recipientString);
  const number = sanitzePhone(recipient);
  if (!!number) {
    return format ? number.formatNational() : number.nationalNumber;
  } else if (isEmail(recipient)) {
    return recipient;
  } else {
    console.info("Removed recipient string during sanitation: ", recipientString)
    return undefined;
  }
};

// Parses and sanitizes an array of raw recipient stings. See `sanitizeRecipientString`.
export const sanitizeRecipientStrings = (recipientStrings, format=false) => {
  return recipientStrings.map(r => sanitizeRecipientString(r, format)).filter(r => !!r);
};

// Parses a raw phone number and returns a metadata object if the string is 
// a valid US phone number.
export const sanitzePhone = (number) => {
  if (!number) {
    return undefined;
  }

  const phoneNumber = parsePhone(number, "US");
  if (!phoneNumber || !phoneNumber?.isValid()) {
    return undefined;
  }
  if (phoneNumber.country !== "US" || !phoneNumber.nationalNumber) {
    return undefined;
  }
  return phoneNumber;
};


/// Local Storage Utilities
///

const previousRecipientsKey = "ZZ_PREVIOUS_RECIPIENTS";

// Returns array of previous recipients.
export function getPreviousRecipients() {
  return ls.get(previousRecipientsKey) || []; 
}

// Overwrites everything at path. Probably don't want to use.
export function setPreviousRecipients(items) {
  return ls.set(previousRecipientsKey, items); 
}

// Updates the backing storage of previous recipients with these recipients, automatically deduping.
export function updatePreviousRecipients(recipients) {
  var previousRecipients = getPreviousRecipients();
  if (Array.isArray(recipients) && recipients.length > 0) {
    _.forEach(recipients, r => {
      if (previousRecipients.indexOf(r) === -1) {
        previousRecipients.push(r)
      }
    });
  }

  // Write back
  ls.set(previousRecipientsKey, previousRecipients);
};