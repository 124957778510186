import React, { useEffect, useState } from "react";
import moment from "moment";

import DeliveryMethodStatus from "../components/DeliveryMethodStatus";
import Spinner from "../components/Spinner";

import GlobalContext from "../contexts/GlobalContext";
import { calendar, time } from '../libs/Date';
import { formatRecipientData } from "../libs/Utility";

import { AutoSizer, Column, Table } from 'react-virtualized';
import { HistoryOutlined, MailOutlined } from "@ant-design/icons";

export default function ReminderTable({ reminders, ...props }) {
  const zzContext = React.useContext(GlobalContext);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {

  }

  function rowRenderer(props) {

    const isSelected = props.rowData?._id === zzContext.focusedItem?._id;
    const newProps = {isSelected, ...props};
    return <ReminderRow {...newProps}/>
  }

  return (
    <div className="reminder-table">
      <AutoSizer>
        {({height, width}) => (
          <Table
            width={width}
            height={height}
            headerHeight={40}
            rowHeight={72}
            rowCount={reminders.length}
            rowGetter={({index}) => reminders[index] || {}}
            rowRenderer={rowRenderer}>
            <Column width={124} label="Delivers" dataKey="delivers" />
            <Column width={72} label="Type" dataKey="Type" />
            <Column width={410} flexGrow={1} label="Info" dataKey="info" />
            <Column width={124} label="Method" dataKey="method" />
          </Table>
        )}
      </AutoSizer>
    </div>
  );
};

function ReminderRow({ rowData, columns, style, isSelected }) {
  const zzContext = React.useContext(GlobalContext);
  const reminder = rowData;
  const isOverdue = reminder.trigger < moment().format('x');

  // Delivers, Info, Method
  const columnComponents = [
    <div className="delivers">
      <div className="date">
      { calendar(reminder.trigger).toUpperCase() }
      </div>
      <div className="time">
        { time(reminder.trigger) }
      </div>
    </div>,

    <div className="icon">
      {rowData.isRich &&
        <HistoryOutlined style={{ fontSize: '20px'}} />
      }
      {!rowData.isRich &&
        <MailOutlined style={{ fontSize: '20px'}} />
      }
    </div>,

    <div className="info">
      {rowData.isRich && <div className="title">
        {reminder.title}
      </div>}
      {!rowData.isRich && <div className="title">
        {reminder.subject}
      </div>}
      {rowData.isRich && <div className="subtitle">
        {formatRecipientData(reminder.to)}
      </div>}
      {!rowData.isRich && <div className="subtitle">
        {reminder.from}
      </div>}

    </div>,

    <div className="delivery-method">
      <DeliveryMethodStatus reminder={rowData} spacing={8} />
    </div>
  ];

  return (
    <div
      className={`reminder-table-row-component ReactVirtualized__Table__row ${isSelected ? "is-selected" : ""} ${isOverdue ? "is-overdue" : ""}`}
      style={style}
      onClick={() => {
        zzContext.setFocusedItem(reminder);
        console.log("Focused: ", zzContext.focusedItem);
      }}
      key={reminder.id}
    >
      {columns.map(({props}, i) =>
        <div
          className="ReactVirtualized__Table__rowColumn"
          style={props.style}
          key={i}
        >
          {columnComponents[i]}
        </div>
      )}
    </div>
  )
}
