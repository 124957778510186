import React, { useEffect, useState } from "react";

// Models
import * as Reminder from "../models/Reminder";

// Context
import * as UserContext from "../libs/UserContext";
import { dateFormat, parseUnix } from '../libs/Date';

// Components
import { Checkbox, Col, Row, Button, DatePicker, Form, Input, Modal, Select, Tag, TimePicker } from "antd";

// Icons
import { MailOutlined, MessageOutlined } from "@ant-design/icons";

// libs
import uuidv4 from "uuid/v4";
import isEmail from 'validator/lib/isEmail';
import * as Utility from "../libs/Utility";

import moment from 'moment-timezone';
const _ = require('lodash');
const { TextArea } = Input;
const { Option } = Select;

export default function ReminderModal({ reminder, isVisible, handleCreate, handleUpdate, handleCancel, ...props }) {
  const userEmail = UserContext.getCurrentUserEmail();

  const isCreateMode = !!!reminder;

  const [form] = Form.useForm();
  const [title, setTitle] = useState(reminder?.name || "New Reminder");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {

  }

  async function onFinish(fieldData) {
    const { title, context, date, time, recipients, ccSelf } = fieldData;
    let _recipients = ccSelf ? [userEmail, ...recipients] : recipients;

    // Write recipients back to LS
    Utility.updatePreviousRecipients(_recipients);

    const deliveryDate = date.set({
      hour: time.get("hour"),
      minute: time.get("minute"),
      second: 0
    })

    // Begin Loading
    setIsLoading(true);

    // Reminder
    let _reminder;
    let _callback;

    if (isCreateMode) {
      _callback = handleCreate;
      _reminder = {
        threadId: uuidv4(),
        from: userEmail,
        title: title,
        context: context || "",
        fromDate: moment().format('x'),
        trigger: parseInt(deliveryDate.format('x')),
        to: Utility.sanitizeRecipientStrings(_recipients),
        isRich: true
      };
      await Reminder.create(_reminder);
    } 
    else {
      console.error("[Reminder][Update] INVALID REQUEST. Updating not yet supported.")

      // _callback = handleUpdate;
      // _reminder = {
      //   text: title,
      //   fromDate: reminder?.fromDate || moment().format('x'),
      //   trigger: parseInt(deliveryDate.format('x')),
      //   to: Utility.sanitizeRecipientStrings(_recipients),
      //   isRich: true
      // };
      // await Reminder.update(_reminder);
    }

    // Stop Loading
    setIsLoading(false);

    // Reset form, return model and dismiss modal
    form.resetFields();
    _callback(_reminder);
  }

  function onTitleUpdate(title) {
    const _title = title.length === 0 ? "*" : title
    setTitle(_title)
  }

  function onSubmit() {
    form.submit();
  }

  function onCancel() {
    form.resetFields();

    handleCancel();
  }

  return (
    <Modal
      title={title}
      visible={isVisible}
      width={922}
      centered={true}
      animation={true}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={onSubmit}
          loading={isLoading}
        >
          {isCreateMode ? "Set" : "Update"} Reminder
        </Button>
      ]}
    >
      <ReminderContent
        reminder={reminder}
        form={form}
        onTitleUpdate={onTitleUpdate}
        onFinish={onFinish}
      />
    </Modal>
  );
}

function ReminderContent({ reminder, form, onTitleUpdate, onFinish, ...props }) {
  const userEmail = UserContext.getCurrentUserEmail();
  const timeFormat = "h:mm A"

  function textFilter(inputValue, path) {
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  }

  const radioStyle = {
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    alignItems: 'center'
  };

  let parsedTriggerDate = moment();
  // if (moment.isRich) {
  parsedTriggerDate = parseUnix(reminder?.trigger)._isValid ? parseUnix(reminder?.trigger) : moment();
  // }
  // console.log(parsedTriggerDate);

  function renderRecipientTag(props) {
    const { label, value, closable, onClose } = props;
    const recipient = Utility.sanitizeRecipientString(label, true);
    return (
      <Tag className={`recipient-tag ${!recipient ? "is-invalid" : ""}`}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {!!recipient &&
          <>
            {isEmail(recipient) ? <MailOutlined /> : <MessageOutlined />}
            <div className="recipient-tag-label" >
              {recipient}
            </div>
          </>
        }
        {!recipient && label}
      </Tag>
    );
  }

  const recipientValidator = (rule, value) => {
    // Catches 'required field' case
    if (!value || value?.length === 0) {
      return Promise.reject("Add at least one recipient");
    }

    // Catches invalid inputs
    const invalidRecipient = value.find(r => !Utility.sanitizeRecipientString(r));
    if (!!invalidRecipient) {
      return Promise.reject(`Invalid recipient: ${invalidRecipient}`);
    } else {
      return Promise.resolve();
    }
  };

  return (
    <div className="reminder-modal-component">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          title: reminder?.title,
          context: reminder?.context,
          date: moment(parsedTriggerDate, dateFormat),
          time: moment(parsedTriggerDate, timeFormat),
          ccSelf: true
        }}
      >
        <Row gutter={24}>
          <Col span={12} >

            {/* Title */}
            <Form.Item
              name="title"
              label="Title"
              colon={false}
              rules={[{ required: true }]}
            >
              <Input
                placeholder="Title"
                onChange={(e) => { onTitleUpdate(e.target.value) }}
              />
            </Form.Item>

            {/* Context */}
            <Form.Item
              name="context"
              label="Context"
              colon={false}
            >
              <TextArea
                placeholder="Context"
                rows={11}
              />
            </Form.Item>
          </Col>
          <Col span={12} >

            {/* Date / Time */}
            <Form.Item
              label="Remind Time"
              colon={false}
              style={{ justifyContent: "space-between", marginBottom: 0 }}
            >
              {/* Date */}
              <Form.Item
                name="date"
                style={{ display: 'inline-block', width: 'calc(50% - 4px)' }}
              >
                <DatePicker format={dateFormat} />
              </Form.Item>

              {/* Time */}
              <Form.Item
                name="time"
                style={{ display: 'inline-block', width: 'calc(50% - 4px)', margin: '0 0 0 8px' }}
              >
                <TimePicker format={timeFormat} use12Hours />
              </Form.Item>

              {/* Timezone */}
              {/*<Form.Item
                name="timezone"
                rules={[{ required: true }]}
                >
                <Cascader
                  defaultValue={[moment.tz.guess()]}
                  options={ _.map(moment.tz.names(), (tz) => { return { value: tz, label: tz}; })}
                  showSearch={{ filter: textFilter, matchInputWidth: true }}
                  notFoundContent="Not found"
                />
              </Form.Item>*/}
            </Form.Item>

            {/* Recipients */}
            <Form.Item
              name="recipients"
              label="Recipients"
              colon={false}
              rules={
                [
                  {
                    transform: (value) => Utility.sanitizeStrings(value),
                    validator: recipientValidator
                  }
                ]
              }
            >
              <Select
                className="recipient-select"
                placeholder="SMS, Email"
                mode="tags"
                size="large"
                tagRender={renderRecipientTag}
                tokenSeparators={[',']}
              >
                {_.map(Utility.getPreviousRecipients(), r => {
                  return <Option key={r}>{Utility.sanitizeRecipientString(r, true)}</Option>
                })}
              </Select>
            </Form.Item>
            <div className="hint-note">
              Add comma-separated Emails and Phone Numbers (US only)
            </div>

            <Form.Item
              name="ccSelf"
              valuePropName="checked"
              colon={false}
            >
              <Checkbox>BCC ({userEmail})</Checkbox>
            </Form.Item>
            <div className="hint-note">
              Receive a copy to your email at delivery time
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
