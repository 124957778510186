// FIXME: rm?

// Local Storage
import ls from "local-storage";

import * as User from "../models/User";

export function getAuthData() {
  return ls.get('authData');
}

export function getCurrentUserId() {
  const authData = getAuthData();
  if (authData != null && typeof authData !== "undefined") {
    console.log('auth', authData.id);
    return authData.id;
  }
  return null;
}

let cachedCurrentUser = {};
export async function getCurrentUser() {
  const userId = getCurrentUserId();
  if (userId && !cachedCurrentUser[userId]) {
    cachedCurrentUser[userId] = await User.getById(userId);
  }
  return cachedCurrentUser[userId];
}

export function getCurrentUserEmail() {
  const authData = getAuthData();
  if (authData != null && typeof authData !== "undefined") {
    console.log('auth', authData.email);
    return authData.email;
  }
  return null;
}

export function getCurrentOrganizationId() {
  // lowercase is legacy. rm at future date?
  return ls.get('organizationId') || ls.get('organizationid');
}
