// React
import React from "react";
import { useParams } from "react-router-dom";

// Components
import Signup from "../components/Signup";

export default function SignupPage(appProps) {
  let { step } = useParams();

  return (
    <div className="signup-page">
      <Signup step={step} />
    </div>
  );
}
