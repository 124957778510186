import React, { useEffect, useState } from "react";

import { Button } from "antd";
import Linkify from "react-linkify";

import DeliveryMethodStatus from "../components/DeliveryMethodStatus";

import { calendar, time, reminderIsPast } from '../libs/Date';
import { formatRecipientData } from "../libs/Utility";

const _ = require('lodash');

export default function Sidebar({ reminder, handlePrimaryAction, handleDeleteAction, ...props }) {

  function renderActions() {
    return (
      <>
        {reminder.isRich && !reminderIsPast(reminder) &&
          <div className="actions">
            <Button
              style={{
                "margin-right": "6px"
              }}
              type="danger"
              onClick={handleDeleteAction}
            >
              Delete
            </Button>
          </div>
        }
      </>
    )
  }

  return (
    <div className="sidebar">
      {!!reminder && (
        <>
          {reminder.isRich &&
            <>
              <ReminderSidebar
                reminder={reminder}
              />
              {renderActions()}
            </>
          }
          {!reminder.isRich &&
            <>
              <EmailSidebar
                email={reminder}
              />
              {renderActions()}
            </>
          }
        </>
      )}
    </div>
  )
}

function SidebarItem({ title, ...props }) {
  return (
    <div className="sidebar-item">
      <div className="title">
        {title}
      </div>
      {props.children}
    </div>
  );
}

function EmailSidebar({ email, ...props }) {
  const timeTitle = reminderIsPast(email) ? "Reminded" : "Reminder time";
  const text = email.text || "";
  const deliveryTs = email.trigger || 0;

  return (
    <>
      <div className="title">
        {email.subject || "<No Email Subject>"}
      </div>

      <div className="time-delivery">
        <SidebarItem title={timeTitle}>
          {calendar(deliveryTs) + ", " + time(deliveryTs)}
        </SidebarItem>

        <SidebarItem title="Delivery Method">
          <DeliveryMethodStatus reminder={email} />
        </SidebarItem>
      </div>

      {!!email.from &&
        <SidebarItem title="From">
          {email.from}
        </SidebarItem>
      }

      {text.length > 0 &&
        <SidebarItem title="Text">
          <Linkify>
            {text}
          </Linkify>
        </SidebarItem>
      }

      {!!email.command &&
        <SidebarItem title="Command">
          <pre>
            {email.command}
          </pre>
        </SidebarItem>
      }

      {!!email.Cc &&
        <SidebarItem title="CC">
          {email.Cc}
        </SidebarItem>
      }

      {!!email.Bcc &&
        <SidebarItem title="BCC">
          {email.Bcc}
        </SidebarItem>
      }
    </>
  );
}

function ReminderSidebar({ reminder, ...props }) {
  const timeTitle = reminderIsPast(reminder) ? "Reminded" : "Reminder time";
  const deliveryTs = reminder.trigger || 0;

  return (
    <>
      <div className="title">
        {reminder.title || "<No Reminder Title>"}
      </div>

      <div className="time-delivery">
        <SidebarItem title={timeTitle}>
          {calendar(deliveryTs) + ", " + time(deliveryTs)}
        </SidebarItem>

        <SidebarItem title="Delivery Method">
          <DeliveryMethodStatus reminder={reminder} />
        </SidebarItem>
      </div>

      <SidebarItem title="Deliver To">
        <Linkify>
          {formatRecipientData(reminder.to) || "No Recipients"}
        </Linkify>
      </SidebarItem>

      {mapAttrs(reminder.attrs)}

      {!!reminder.context &&
        <SidebarItem title="Context">
          {reminder.context}
        </SidebarItem>
      }
    </>
  );
}

function mapAttrs(attrs) {
  let attrsComps = [];
  _.forIn(attrs, (v, k) => {
    if (!!v && v.length > 0) {
      attrsComps.push(
        <SidebarItem title={k}>
          {v}
        </SidebarItem>
      );
    }
  });
  return attrsComps;
}
