const config = {
  devApiUrl: process.env.REACT_APP_USE_LOCAL ? "http://localhost:3003/dev" : "https://<DEV_API_ENDPOINT>/dev",
  prodApiUrl: "https://5hsq7als6k.execute-api.us-west-2.amazonaws.com/prod"
};

const AWSEnvironmentDev = {
};

const AWSEnvironmentProd = {
};

// Default to dev if not set
const AWSConfig = process.env.REACT_APP_STAGE === 'prod'
  ? AWSEnvironmentProd
  : AWSEnvironmentDev;

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...AWSConfig,
  ...config
};
