// React
import React from "react";
import { Route, Switch } from "react-router-dom";

// Router
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

// Default Pages
import NotFound from "./pages/NotFound";

// Pages
import Home from "./pages/Home";

// Components
import Root from "./components/Root";

// Auth Pages
import Login from "./pages/Login";
import Signup from "./pages/Signup";

export default function Routes({ appProps }) {
  return (
    <Root>
      <Switch>
        <AuthenticatedRoute path="/" exact component={Home} appProps={appProps} />
        <UnauthenticatedRoute path="/login" exact component={Login} appProps={appProps} />
        <UnauthenticatedRoute path="/signup" exact component={Signup} appProps={appProps} />
        <UnauthenticatedRoute path="/signup/:step" exact component={Signup} appProps={appProps} />
        
        { /* Finally, catch all unmatched routes */ }
        <Route component={NotFound} />
      </Switch>
    </ Root>
  );
}
