// React
import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";

export default function Header(props) {
  return (
    <div className="header-component">
      <div className="content">
        <div className="logo">ZZ Reminders</div>
      </div>
    </div>
  );
}
