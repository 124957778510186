import { notification } from "antd";

export default function Notification() { return }

// Creation Success
export function showSuccess(reminder) {
  if (!isValidReminder(reminder)) {
    return;
  }

  const { reminderType, description } = fieldsFromReminder(reminder);
  showNotification(
    reminderType + " Created",
    description,
    "success"
  )
}

// Update Success
export function showUpdate(reminder) {
  if (!isValidReminder(reminder)) {
    return;
  }

  const { reminderType, description } = fieldsFromReminder(reminder);
  showNotification(
    reminderType + " Updated",
    description,
    "success"
  )
}

// Simple Notification
// type: [success, info, warning, error]
export function showNotification(title, description, type) {
  notification[type]({
    message: safeString(title),
    description: safeString(description)
  })
}

/// Utilities

function isValidReminder(reminder) {
  const isValid = !!reminder;
  if (!isValid) {
    console.error("Notificaiton reminder param is undefined")
  }

  return isValid;
}

function fieldsFromReminder(reminder) {
  console.log(reminder);
  const reminderType = safeString(reminder.isRich ? "Reminder" : "Email Reminder");
  const description = safeString(reminder.isRich ? reminder.title : reminder.subject);
  return {
    reminderType: reminderType,
    description: description
  }
}

function safeString(string) {
  if (!!!string || string.length === 0 || string === "") {
    console.error("Invalid Notification string param");
  }

  return string || "";
}
